<template>
  <div id="LatestJudgementDetails" class="m-6" >
          <section>
        <b-tabs type="is-boxed">
            <b-tab-item label="Summary" icon="google-photos">
              <!--  Summary -->
              <Summary  :summary="summarydata" />
              <!--  Summary Component  -->
            </b-tab-item>
            <b-tab-item v-if="summarydata.data.followins.length !== 0"  label="Follow Ins" icon="gesture-swipe-down">

              <Followins  :followins="summarydata.data.followins"  />
            </b-tab-item>
            <b-tab-item  v-if="summarydata.data.followups.length !== 0"  label="Follow Ups" icon="gesture-swipe-up">

              <Followups  :followups="summarydata.data.followups" />
            </b-tab-item>

            <b-tab-item v-if="summarydata.data.statutes.length !== 0"  label="Statutes" icon="scale-balance">
                  <Statutes  :statutes="summarydata.data.statutes" />
            </b-tab-item>
            <b-tab-item v-if="summarydata.data.sids.length !== 0" label="Subjects" icon="book-edit-outline">
                    <Subjects  :subjects="summarydata.data.sids" />
            </b-tab-item>


          </b-tabs>
          </section>
  </div>
</template>
<script>
const axios = require('axios');
import Summary from "@/components/JudgementDetails/Summary.vue";
import Followins from "@/components/JudgementDetails/Followins.vue";
import Followups from "@/components/JudgementDetails/Followups.vue";
import Statutes from "@/components/JudgementDetails/Statutes.vue";
import Subjects from "@/components/JudgementDetails/Subjects.vue";
export default {
    name: 'LatestJudgementDetails',
    components:{ Summary , Followins, Followups, Statutes, Subjects},
    props: {
      slugid: {
        default: null,
      },
  },
  data() {
      return {
          summarydata:[]
      }
    },
    mounted (){
          this.getJudgementDetailsByID();
          console.log(this.slugid);
          console.log('this.slugid');
    },
    methods:{
        getJudgementDetailsByID(){
           var idtoCall = this.slugid;
          axios.get('https://staging.spotlawapp.com/newapi/getJudgementDetails.php?idJudgement=' + idtoCall).then( response=> {
                this.summarydata = response;
console.log(response, "wer")
          })

        }

    }

}
</script>

