  <template>
    <div class="summary">
      <div class="columns">
          <div class="column">
              <div class="box" v-if="followups.length == 0"> No Folloups</div>
              <div class="box" v-for="follow in followups"  v-bind:key="follow.id" >
                  {{ follow.Appellant }}<b> Vs </b> {{ follow.Respondent }}
                    <br>
                    <br>
                  {{ follow.Citation }}
                   <br>
                  <button class="button is-link mt-2">Refer in para</button>
              </div>
          </div>
          <div class="column">
          </div>
      </div>

    </div>
</template>
<script>
export default {
  name: 'followups',
  props:{
    followups: []
  }

}
</script>
