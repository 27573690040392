  <template>
    <div class="summary">
      <div class="columns">
          <div class="column not-border-bottm is-4">
              <div class="box">
                <article class="media">
                  <!-- <div class="media-left">
                    <figure class="image is-54x54">
                      <img src="@/assets/images/appvsres.png" alt="Image">
                    </figure>
                  </div> -->
                  <div class="media-content">
                    <div class="content">
                             <!-- <strong> Appellants </strong> VS <strong> Respondent </strong> <br> -->
                              <span  v-for="applant in summary.data.appellants"  v-bind:key="applant.id">
                                {{ applant.Appellant }}
                              </span>
                            <strong>  VS </strong>
                              <span  v-for="respondent in summary.data.respondents"  v-bind:key="respondent.id">
                                {{ respondent.Respondent }}
                              </span> <br>
                             <strong> DOJ :  </strong>  {{ summary.data.judgement[0].DOJ }} <br>
                              <strong>Citation</strong>
                       {{ summary.data.citations[0].Citation }}
                    </div>
                  </div>
                </article>
              </div>
          </div>
          <div class="column not-left-btm-border is-8">
            <div class="box">
              <article class="media">
                <div class="media-left">
                  <figure class="image is-54x54">
                     <img src="@/assets/images/judges.png" alt="Image">
                  </figure>
                </div>
                <div class="media-content">
                   <div class="content">
                    <strong>Judges :</strong>
                    <span v-for="judge in summary.data.judges" v-bind:key="judge.id">

                     {{ judge.Judge }} ({{ summary.data.judges[0].Role }}) <strong> , </strong>
                    </span> <br>
                   <strong> Opinion : </strong>
                   <span  v-for="judge in summary.data.judges" v-bind:key="judge.id">
                   {{judge.Opinion}} ,
                   </span>
                  </div>
                </div>
              </article>
            </div>
          </div>
          <!-- <div class="column not-left-btm-border">
            <div class="box">
              <article class="media">
                <div class="media-left">
                  <figure class="image is-54x54">
                    <img src="@/assets/images/citation.png" alt="Image">
                  </figure>
                </div>
                <div class="media-content">
                  <div class="content">
                    <p>
                      <strong>Citation</strong>
                      <br>
                       {{ summary.data.citations[0].Citation }}
                    </p>
                  </div>
                </div>
              </article>
            </div>
          </div> -->
      </div><!-- Columns -->


      <div class="columns">
          <div class="column all-border">
               <div class="box">
              <article class="media">
                <div class="media-left">
                  <figure class="image is-54x54">
                    <img src="@/assets/images/summary.png" alt="Image">
                  </figure>
                </div>
                <div class="media-content">
                  <div class="content">
                    <strong>HeadNote </strong>
                    <div  v-for="judgementPdf in summary.data.judgement" v-bind:key="judgementPdf.id">
                      {{judgementPdf.noteText}} <br>
                      <b-button class="pdf-btn" label="View PDF" type="is-primary" size="is-medium" @click="isCardModalActive = true" icon-left="file-powerpoint" />
                       <p>
                        <!--  <iframe :src='"https://spotlawapp.com/"+judgementPdf.pdfURL' height="500" width="100%" title="Iframe Example"></iframe> -->
                      <!--    <router-link target="_blank" :to='"https://spotlawapp.com/"+judgementPdf.pdfURL'> https://spotlawapp.com/{{judgementPdf.pdfURL}} </router-link> --> </p>
                    </div>

                  </div>

<!-- b-dmoeal start -->
                   <b-modal v-model="isCardModalActive" :width="900" scroll="keep">
            <div class="card">

                <div class="card-content" v-for="judgementPdf in summary.data.judgement" v-bind:key="judgementPdf.id">
                    <iframe :src='"https://spotlawapp.com/"+judgementPdf.pdfURL' height="500" width="100%" title="Iframe Example"></iframe>

                </div>
            </div>
        </b-modal>


                </div>
              </article>
            </div>
          </div>
      </div>

    </div>
</template>
<script>
export default {
  name: 'Summary',
  props:{
    summary: {}
  },
  data(){
    return{
        isCardModalActive: false
    }
  }

}
</script>
