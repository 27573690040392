  <template>
    <div class="summary">
      <div class="columns">
          <div class="column">
              <div class="box" v-if="statutes.length == 0"> No Statutes</div>
              <div class="box is-display-flex follow-box" v-for="statutes in statutes"  v-bind:key="statutes.id" >
                 <div class="columns">
                    <div class="column">
                        <span> {{ statutes.Statute }} </span>
                    </div>
                    <div class="column has-text-centered">
                       <span> {{statutes.artSection}} </span>
                    </div>
                    <div class="column has-text-right">
                     <button class="button is-link mt-2  grey-bg">Refer in para</button>
                    </div>
                </div>

              </div>
          </div>

      </div>

    </div>
</template>
<script>

export default {
  name: 'Statutes',
  props:{
    statutes: []
  }

}
</script>
