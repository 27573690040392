  <template>
    <div class="summary">
      <div class="columns">
          <div class="column">
              <div class="box" v-if="followins.length == 0"> No Folloups</div>
              <div class="box is-display-flex follow-box" v-for="follow in followins"  v-bind:key="follow.id" >
                <div class="columns">
                    <div class="column">
                        <span> {{ follow.Appellant }}<b> Vs </b> {{ follow.Respondent }} </span>
                    </div>
                    <div class="column has-text-centered">
                       <span> {{ follow.Citation }} </span>
                    </div>
                    <div class="column has-text-right">
                      <button class="button is-link mt-2 grey-bg">Refer in para</button>
                    </div>
                </div>



              </div>
          </div>

      </div>

    </div>
</template>
<script>
import "../../assets/style/custom.css"
export default {
  name: 'followins',
  props:{
    followins: []
  }

}
</script>
