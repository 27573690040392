  <template>
    <div class="summary">
      <div class="columns">
          <div class="column">
              <div class="box" v-if="subjects.length == 0"> No Subjects</div>
              <div class="box is-display-flex follow-box" v-for="subjects in subjects"  v-bind:key="subjects.id" >
                 <div class="columns">
                    <div class="column">
                        <span>  {{subjects.Subject}} <b> Vs </b> {{subjects.Issue}} </span>
                    </div>

                    <div class="column has-text-right">
                      <button class="button is-link mt-2 grey-bg">Refer in para {{subjects.Decision}}</button>
                    </div>
                </div>


              </div>
          </div>

      </div>

    </div>
</template>
<script>
export default {
  name: 'Subjects',
  props:{
    subjects: []
  }

}
</script>
